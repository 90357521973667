import { toast } from "react-toastify";
import CommonService from "../services/common/common-service";
const commonService = new CommonService();

export const getDNAPaymentScript = (isTestMode: boolean): string => {
  return isTestMode
    ? "https://test-pay.dnapayments.com/checkout/payment-api.js"
    : "https://pay.dnapayments.com/checkout/payment-api.js";
};

export const getDNAPaymentScriptIntegrity = (isTestMode: boolean): string => {
  return isTestMode
    ? "sha256-z27fIyoBGu/jlBtWNBta9k6bAfpIqeTuEVxceOXV53k="
    : "sha256-A3y2uxmrya0WIZUTucuGnBjtT4PZ2+joczNt+ry+JOM=";
};

export const getPublicConfigurationValue = async (configKey: string) => {
  let value;
  await commonService
    .getConfigurationDetail({ code: configKey })
    .then(async (res) => {
      value = res.Data.find((x) => x.reference_code === configKey).value;
    })
    .catch((m) => {
      if (m.response && m.response.data && m.response.data.message) {
        toast.error(m.response.data.message);
      } else {
        toast.error(m.toString());
      }
    });
  return value;
};

import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Helmet } from "react-helmet";
import { IPaymentFailProps } from "../../types/propTypes";
import PaymentService from "../../services/payment/payment-service";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import StandardResponse from "../../models/standard-response";
import { ITransactionDetails, IPaymentInvoice } from "../../models/payment";
import {
  getDNAPaymentScript,
  getPublicConfigurationValue,
} from "../../utils/payment";
import "./style.css";
import { Common } from "../../utils/constants";

const paymentService = new PaymentService();

const PaymentFail: FC<IPaymentFailProps> = (props) => {
  const { t } = props;
  const { code, invoice_id, connector_id } = useParams();
  const navigate = useNavigate();
  const [transactionDetails, setTransactionDetails] =
    useState<ITransactionDetails | null>(null);

  useEffect(() => {
    const getTransactionDetails = async () => {
      await paymentService
        .getTransactionDetails(invoice_id)
        .then((res: StandardResponse<ITransactionDetails>) => {
          if (res.Data && Object.keys(res.Data).length !== 0) {
            setTransactionDetails(res.Data);
          }
        })
        .catch((m) => {
          if (m.response && m.response.data && m.response.data.message) {
            toast.error(m.response.data.message);
          } else {
            toast.error(m.toString());
          }
        });
    };

    getTransactionDetails();
  }, []);

  const backbtnclick = () => {
    navigate("/");
  };

  const handleRetry = async () => {
    const enable_google_apple_pay = await getPublicConfigurationValue(
      Common.ConfigurationKey.ENABLE_GOOGLE_PAY_APPLE_PAY_DNA
    );
    const email = localStorage.getItem("email") || "";

    if (connector_id && email) {
      const paymentInvoice = await createPaymentInvoice({
        connector_id,
        email,
      });
      if (paymentInvoice) {
        (document as any)
          .getElementById("loading")
          .classList.remove("spinner-disable");
        const isTestMode =
          paymentInvoice.isTestMode === undefined ||
          paymentInvoice.isTestMode === null ||
          paymentInvoice.isTestMode === true
            ? true
            : false;

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = getDNAPaymentScript(isTestMode);
        script.nonce = "ils+vsV+OAN1z2QUGBzoyQ==";
        script.async = true;
        script.onload = () => {
          (window as any).DNAPayments.configure({
            autoRedirectDelayInMs: 3000,
            scopes: {
              allowHosted: true,
            },
            paymentMethods: [
              {
                name: (window as any).DNAPayments.paymentMethods.BankCard,
              },
              ...(enable_google_apple_pay === "true"
                ? [
                    {
                      name: (window as any).DNAPayments.paymentMethods
                        .GooglePay,
                    },
                    {
                      name: (window as any).DNAPayments.paymentMethods.ApplePay,
                    },
                  ]
                : []),
            ],
            isTestMode: isTestMode,
            paymentTimeoutInSeconds: 900,
          });

          const invoiceId = paymentInvoice.invoice_id;

          const paymentData = {
            paymentSettings: {
              terminalId: paymentInvoice.terminal,
              returnUrl: `${window.location.origin}/charge-station/${code}/${connector_id}/payment/success/${invoiceId}`,
              failureReturnUrl: `${window.location.origin}/charge-station/${code}/${connector_id}/payment/fail/${invoiceId}`,
              callbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
              failureCallbackUrl: `${process.env.REACT_APP_API_SERVER}/${process.env.REACT_APP_API_VERSION}/web-pay/payment/dna-webhook/${invoiceId}`,
              cscMatrix: 22,
              paMatrix: 3,
            },
            customerDetails: {
              accountDetails: {
                accountId: invoiceId,
              },
            },
            periodic: {
              periodicType: "ucof",
            },
            invoiceId: invoiceId,
            amount: +paymentInvoice.amount,
            auth: { access_token: paymentInvoice.access_token },
            language: "en",
            description: "Pre auth to InstaVolt",
            transactionType: paymentInvoice.transaction_type || "PRE-AUTH",
            currency: paymentInvoice.currency,
          };

          (window as any).DNAPayments.openPaymentPage(paymentData);
        };
        script.onerror = () => {
          (document as any)
            .getElementById("loading")
            .classList.add("spinner-disable");
          toast.error(t<string>("paymentFail.common.error"));
        };
        document.body.appendChild(script);
      }
    } else {
      toast.error(t<string>("paymentFail.common.error"));
      navigate("/");
    }
  };

  const createPaymentInvoice = async (inputData) => {
    let paymentInvoice: any = "";
    await paymentService
      .createPaymentInvoice(+inputData.connector_id, inputData.email)
      .then(async (res: StandardResponse<IPaymentInvoice>) => {
        if (res.Data) {
          paymentInvoice = res.Data;
        }
      })
      .catch((m) => {
        if (m.response && m.response.data && m.response.data.message) {
          toast.error(m.response.data.message);
        } else {
          toast.error(m.toString());
        }
      });
    return paymentInvoice;
  };

  return (
    <>
      <Helmet>
        <title>{t<string>("paymentFail.title")}</title>
      </Helmet>
      <div className="card-body text-center payment-fail">
        <div className="row min-vh-50 align-items-center">
          <div className="col">
            <div className="mesg-box text-center">
              <img
                src={require("../../assets/images/error-icon.svg").default}
                alt="error"
              />
              <h2 className="error-color">{t<string>("paymentFail.title")}</h2>
              <p>{t<string>("paymentFail.failureLabel")}</p>
            </div>
            {transactionDetails && transactionDetails.error_message ? (
              <div className="mt-3 error-color">
                <p className="error-message">
                  {transactionDetails.error_message}
                </p>
              </div>
            ) : (
              <></>
            )}

            <div className="clearfix mt-4">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={() => handleRetry()}
              >
                <span>{t<string>("paymentFail.button.retry")}</span>
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => backbtnclick()}
              >
                <span>{t<string>("paymentFail.button.back")}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentFail;
